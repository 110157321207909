<template>
  <v-card>
    <v-toolbar color="color2 color2Text--text">
      <v-toolbar-title>Order Locator</v-toolbar-title>
    </v-toolbar>
    <v-card-text class="text-center">
      <v-container>
        <v-row dense>
          <v-col cols="12" md="6" offset-md="3">
            <v-text-field
              label="Order Id"
              hint="Enter the order id found in the receipt that was emailed to you"
              persistent-hint
              v-model="iOrderId"
              required
              :error-messages="orderIdErrors"
              @blur="$v.iOrderId.$touch()"
              color="color3"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense v-if="tellToLogin">
          <v-col cols="12" md="6" offset-md="3">
            <v-alert type="info" :value="true" prominent text>
              Please login to access this order.
            </v-alert>
          </v-col>
        </v-row>
        <v-row dense v-if="askForEmail">
          <v-col cols="12" md="6" offset-md="3">
            <v-text-field
              label="Email address"
              hint="Enter the email address the receipt was emailed to"
              persistent-hint
              v-model="iEmail"
              required
              :error-messages="emailErrors"
              @blur="$v.iEmail.$touch()"
              color="color3"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense v-if="error">
          <v-col cols="12" md="6" offset-md="3">
            <v-alert
              text prominent
              type="error"
              :value="true"
            >{{error.error}}</v-alert>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" md="6" offset-md="3">
            <v-btn
              color="color3 color3Text--text"
              :disabled="$v.$invalid"
              :loading="loading"
              @click="getBag"
            >Find my order</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'
import orderid from '@/Validators/OrderId'

export default {
  props: ['orderId', 'loading', 'needEmail', 'invalid', 'mismatch', 'login'],
  mixins: [validationMixin],
  data () {
    return {
      iOrderId: null,
      iEmail: null
    }
  },
  computed: {
    user () {
      return this.$store.getters.user
    },
    emailErrors () {
      const errors = []
      if (!this.$v.iEmail.$dirty) return errors
      !this.$v.iEmail.required && errors.push('E-mail is required')
      !this.$v.iEmail.email && errors.push('Must be valid e-mail')
      return errors
    },
    orderIdErrors () {
      const errors = []
      if (!this.$v.iOrderId.$dirty) return errors
      !this.$v.iOrderId.required && errors.push('Order Id is required')
      !this.$v.iOrderId.orderid && errors.push('This is not a valid Order Id')
      return errors
    },
    askForEmail () {
      return this.needEmail.find(f => f === this.iOrderId)
    },
    tellToLogin () {
      return this.login.find(f => f === this.iOrderId)
    },
    error () {
      return this.invalid.find(f => f.id === this.iOrderId) || this.mismatch.find(f => f.id === this.iOrderId && f.email === this.iEmail)
    }
  },
  validations () {
    return {
      iOrderId: { required, orderid },
      iEmail: this.askForEmail ? { required, email } : {}
    }
  },
  methods: {
    getBag () {
      this.$emit('get-bag-click', { id: this.iOrderId, email: this.iEmail })
    }
  },
  watch: {
    user: function (val) {
      if (val && this.tellToLogin) this.getBag()
    }
  },
  mounted () {
    this.iOrderId = this.orderId
    this.iEmail = this.email
  }
}
</script>
