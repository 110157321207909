<template>
  <v-card>
    <v-toolbar color="color2 color2Text--text">
      <v-toolbar-title>{{order ? order.organization.name : ''}}</v-toolbar-title>
    </v-toolbar>
    <loading-bar :value="loading"></loading-bar>
    <v-card-text class="pt-1">
      <div class="headline grey--text" v-if="order && order.status !== 'Pending'">{{order && order.status}}</div>
      <div class="text-right grey--text caption">Order Id: {{order && order.id}}</div>
      <div class="text-right grey--text caption">{{order && dt(order.dtPaid)}}</div>
      <v-container v-if="order">
        <template v-for="(item,i) in items">
          <div :key="i+'b'" class="pt-0">
            <v-row dense>
              <v-col cols="8" sm="10" xl="11">
                <div class="title">{{item.name}}</div>
                <div class="subheading">{{item.description}}</div>
              </v-col>
              <v-col cols="4" sm="2" xl="1" class="d-flex justify-center text-right" style="flex-direction: column;">
                <div class="title">{{(item.waitlist ? 'Wailist' : item.amount) | usDollars}}</div>
                <div v-if="item.status !== 'Paid'" class="subheading grey--text text-right">{{item.status}}</div>
              </v-col>
            </v-row>
            <template v-if="item.registration">

              <v-row dense v-if="item.registration.isDeleted">
                <v-col cols="12" sm="10" offset-sm="1">
                  <v-alert type="error" text :value="true" class="text-center">
                    This team has been withdrawn from the tournament
                  </v-alert>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="3" offset-sm="1" md="2" class="py-0">
                  <strong>Team:</strong>
                </v-col>
                <v-col cols="12" sm="8" md="9" class="py-0">
                  {{item.registration.participant}}
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="3" offset-sm="1" md="2" class="py-0">
                  <strong>Tournament:</strong>
                </v-col>
                <v-col cols="12" sm="8" md="9" class="py-0">
                  {{item.registration.event}}
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="3" offset-sm="1" md="2" class="py-0">
                  <strong>Division:</strong>
                </v-col>
                <v-col cols="12" sm="8" md="9" class="py-0">
                  {{item.registration.division}}
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="3" offset-sm="1" md="2" class="py-0">
                  <strong>Location:</strong>
                </v-col>
                <v-col cols="12" sm="8" md="9" class="py-0">
                  {{item.registration.location}}
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="3" offset-sm="1" md="2" class="py-0">
                  <strong>Start Date:</strong>
                </v-col>
                <v-col cols="12" sm="8" md="9" class="py-0">
                  {{formatDate(item.registration.startDate)}}
                </v-col>
              </v-row>
            </template>
            <v-row dense v-if="order.status !== 'Payment Requested'">
              <v-col cols="12" sm="8" md="9" class="py-0">
                <template v-if="managable(item)">
                  <v-btn
                    color="color3"
                    text small
                    class="ma-0 pa-0 pr-2"
                    @click.stop="manage(item)"
                  >Manage</v-btn>
                  <span class=" pr-2">|</span>
                </template>
                <v-btn
                  color="color3"
                  text small
                  class="ma-0 pa-0"
                  @click.stop="getHistory(item.id)"
                >Payment Details</v-btn>
              </v-col>
            </v-row>
          </div>
          <v-divider :key="i+'c'"></v-divider>
        </template>
          <!-- Promo row -->
          <v-row dense class="mt-1" v-if="promoAmount">
              <v-col cols="8" sm="10" xl="11" class="title text-right py-0">
                Promo Code {{promos | formatArray}}:
              </v-col>
              <v-col cols="4" sm="2" xl="1" class="title text-right py-0">
                {{promoAmount | usDollars}}
              </v-col>
          </v-row>
          <!-- Subtotal row -->
          <v-row dense class="mt-1 grey--text" v-if="amountObj.showSub">
            <v-col cols="8" sm="10" xl="11" class="title text-right py-0">
              Subtotal:
            </v-col>
            <v-col cols="4" sm="2" xl="1" class="title text-right py-0">
              {{amountObj.subtotal | usDollars}}
            </v-col>
          </v-row>
          <!-- Fees row -->
          <v-row dense v-if="amountObj.feeTotal" class="mt-n2 grey--text">
            <v-col cols="8" sm="10" xl="11" class="title text-right py-0">
              {{amountObj.feeLabel}}:
            </v-col>
            <v-col cols="4" sm="2" xl="1" class="title text-right py-0">
              {{amountObj.feeTotal | usDollars}}
            </v-col>
          </v-row>
          <!-- Tax row -->
          <v-row dense v-if="amountObj.taxTotal" class="mt-n2 grey--text">
            <v-col cols="8" sm="10" xl="11" class="title text-right py-0">
              {{amountObj.taxLabel}}:
            </v-col>
            <v-col cols="4" sm="2" xl="1" class="title text-right py-0">
              {{amountObj.taxTotal | usDollars}}
            </v-col>
          </v-row>
          <!-- Total row -->
          <v-row dense :class="{ 'mt-1': !amountObj.showSub, 'mt-n2': amountObj.showSub}">
            <v-col cols="8" sm="10" xl="11" class="title text-right py-0">
              Total:
            </v-col>
            <v-col cols="4" sm="2" xl="1" class="title text-right py-0">
              {{amountObj.total | usDollars}}
            </v-col>
          </v-row>
        <v-alert
          type="error"
          :value="order.status === 'Payment Requested' && !hasDeleted"
          prominent
        >
          <v-row align="center">
            <v-col class="grow">
              <strong class="white--text">{{order ? order.organization.name : ''}} has request payment for this order.</strong>
            </v-col>
            <v-col class="shrink">
              <v-btn color="black white--text" @click.stop="addToCart">add to cart</v-btn>
            </v-col>
          </v-row>
        </v-alert>
      </v-container>
    </v-card-text>

    <v-dialog
      v-model="dialog"
      scrollable
      max-width="500px"
      transition="dialog-transition"
      :persistent="busy"
    >
      <v-card>
        <v-toolbar color="color1 color1Text--text">
          <v-toolbar-title>Payment History</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            color="color2 color2Text--text"
            fab small
            @click.stop="dialog = false"
            :disabled="busy"
          >
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-toolbar>
        <loading-bar :value="iLoading"></loading-bar>
        <v-card-text class="pa-0">
          <payment-history
            :bagItemId="historyId"
            @busy-change="onBusyChange"
            :credentials="credentials"
            :embedded="true"
            :active="dialog"
          ></payment-history>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import moment from 'moment'
import sum from 'lodash.sum'
import { mapGetters } from 'vuex'
import { getUrl } from '@/classes/_URL'
import * as actions from '@/store/ActionTypes'
import CartItem from '@/classes/CartItem'
import { getAmountObj } from '@/classes/HelperFunctions'
const PaymentHistory = () => import('@/components/Cart/PaymentHistory.vue')

export default {
  props: ['order', 'credentials', 'loading'],
  data () {
    return {
      request: null,
      iLoading: false,
      historyId: null,
      dialog: false,
      busy: false
    }
  },
  computed: {
    ...mapGetters(['subdomain', 'user']),
    promos () {
      return this.order.items.filter(f => f.promo).map(m => m.promo)
    },
    promoAmount () {
      return sum(this.order.items.map(m => m.promoAmount))
    },
    items () {
      return this.user && this.user.vbl2 ? this.order.items : this.order.items.filter(f => f.type !== 'OPAY')
    },
    amountObj () {
      return getAmountObj(this.items.map(m => new CartItem(m)))
    },
    hasDeleted () {
      return !!this.items.find(i => i.registration && i.registration.isDeleted)
    }
  },
  methods: {
    formatDate (date) {
      const d = date.split('T')
      return moment(d[0]).format('dddd, MMMM Do YYYY')
    },
    formatDateTime (date) {
      return moment(date).format('LT [on] dddd, MMM Do')
    },
    dt (date) {
      return moment(date).format('L LT')
    },
    getHistory (id) {
      this.historyId = id
      this.dialog = true
    },
    onBusyChange (val) {
      this.busy = val
    },
    manage (item) {
      if (item.type === 'MEM') {
        const u = this.order.organization.username
        const rel = `/member/${item.memberId}?tab=membership`
        if (this.subdomain === this.order.organization.username) {
          this.$router.push(rel)
        } else {
          const url = getUrl(u, rel)
          window.location = url
        }
      } else {
        const tLink = `/tournament/${item.registration.eventId}/team/${item.registration.participantId}${item.token ? '?token=' : ''}${item.token || ''}`
        this.$router.push(tLink)
      }
    },
    managable (item) {
      return item.type === 'MEM' ? !!item.memberId : !!(item.registration && item.registration.eventId && item.registration.participantId)
    },
    addToCart () {
      const item = new CartItem()
      item.newOrderPayment(this.order)
      this.$store.dispatch(actions.ADD_CART_ITEM, item)
      this.$router.push('/checkout')
    }
  },
  components: {
    PaymentHistory
  }
}
</script>

<style scoped>
.link {
  cursor: pointer;
}
</style>
